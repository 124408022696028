import { normalize } from 'normalizr'
import { Schemas } from 'redux/schema'
import { updateEntities, deleteEntity } from 'redux/entities'

import { canRenderImageType } from 'shared/functions/canRenderImageType'

import apiV2 from 'utils/apiV2'

import { handleError } from '../utils'

const FETCH_REQUEST = 'contenthouse/projectFile/FETCH_REQUEST'
const FETCH_SUCCESS = 'contenthouse/projectFile/FETCH_SUCCESS'
const FETCH_FAILURE = 'contenthouse/projectFile/FETCH_FAILURE'

const CREATE_REQUEST = 'contenthouse/projectFile/CREATE_REQUEST'
const CREATE_SUCCESS = 'contenthouse/projectFile/CREATE_SUCCESS'
const CREATE_FAILURE = 'contenthouse/projectFile/CREATE_FAILURE'

const UPDATE_REQUEST = 'contenthouse/projectFile/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'contenthouse/projectFile/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'contenthouse/projectFile/UPDATE_FAILURE'

const DELETE_REQUEST = 'contenthouse/projectFile/DELETE_REQUEST'
const DELETE_SUCCESS = 'contenthouse/projectFile/DELETE_SUCCESS'
const DELETE_FAILURE = 'contenthouse/projectFile/DELETE_FAILURE'

// Initial State
const initialState = {
  creating: false,
  deleting: false,
  errors: [],
  loaded: false,
  loading: false,
  result: {},
  updating: false,
}

// Actions
export function fetchRequest() {
  return {
    type: FETCH_REQUEST,
  }
}

export function fetchSuccess(payload = {}) {
  return {
    type: FETCH_SUCCESS,
    payload,
  }
}

export function fetchFailure(errors = []) {
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest() {
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess() {
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors = []) {
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest() {
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess() {
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors = []) {
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function deleteRequest() {
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess() {
  return {
    type: DELETE_SUCCESS,
  }
}

export function deleteFailure(errors = []) {
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

// Action Creators
export function loadProjectFiles(id, options) {
  return (dispatch) => {
    dispatch(fetchRequest())

    const promise = apiV2(`/projects/${id}/files`, options)
      .then(({ data, headers }) => {
        const sasToken = headers['x-sas-token']
        const updateData = data?.map((file) => ({
          ...file,
          ...(file?.uri &&
            sasToken &&
            (canRenderImageType(file.content_type) || file.content_type === 'application/pdf') && { preview: `${file.uri}?${sasToken}` }),
        }))

        const normalizedJson = normalize(updateData, Schemas.PROJECT_FILE_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess(updateData))

        return { success: true, data: updateData }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function createBlobUrl(file) {
  const config = {
    method: 'POST',
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = apiV2(`/projects/${file.projectId}/files/upload`, file, config)
      .then(({ data }) => {
        dispatch(createSuccess())
        return { success: true, result: data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function deleteProjectFile(file) {
  const config = {
    method: 'DELETE',
  }

  return (dispatch) => {
    dispatch(deleteRequest())

    const promise = apiV2(`/projects/${file.projectId}/files/${file.encodedId}`, { permanent: true }, config)
      .then(() => {
        const normalizedJson = normalize(file, Schemas.PROJECT_FILE)
        dispatch(deleteEntity(normalizedJson))
        dispatch(deleteSuccess())

        return { success: true, result: file }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(deleteFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function updateProjectFileNote(file, options = {}) {
  const config = {
    method: 'PATCH',
    data: file.metadata,
  }

  return (dispatch) => {
    dispatch(updateRequest())

    const promise = apiV2(`/projects/${file.projectId}/files/${file.encodedId}`, options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data ? JSON.parse(data) : {}, Schemas.PROJECT_FILE)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, result: data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}) {
  const { type, payload, errors } = action

  switch (type) {
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
        result: payload,
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors,
      }
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors,
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors,
      }
    case DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
      }
    case DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        errors: [],
      }
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        errors,
      }
    default:
      return state
  }
}
